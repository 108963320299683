import React from "react"
import styled from "styled-components"
import Layout, { ContentWrapper } from "../../components/layout"
import { BackToLab } from "../../components/link"
import SEO from "../../components/seo"
import { Header } from "../../components/theme"

const Card = styled.div`
  background: #f5f5f5;
  height: 92%;
  width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  transform: translate3d(0, 0, 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 8px;
`
const Paragraph = styled.div`
  position: absolute;
`
const Wrap = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 16px;
`
const TypedKey = styled.h1`
  font-family: "Outfit", sans-serif;
  background: #fff;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SoundButton = styled.button`
  border: 0;
  background: transparent;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
  color: #666;
  border-bottom: 2px solid #666;
  &:hover {
    border-bottom: 2px solid transparent;
  }
`
const TypeButton = styled.button`
  border: 0;
  font-size: 12px;
  background: transparent;
  padding: 5px 10px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  line-height: 1;
  font-weight: 400;
  outline: none;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  font-family: Cousine, monospace;
  &:hover {
    background: #fff;
    font-weight: 600;
  }
`
const Word = styled.span`
  position: relative;

  ${({ active }) =>
    active &&
    `
    font-weight: 600;
  color: #fff;
    &:after {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      background: #fff;
      position: absolute;
      bottom: -2px;
      border-radius:10px;
      left: 40%;
    }
        `}
`
const Shadow = styled.div`
  background: linear-gradient(#666, #666);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, -10px);
  border-radius: 8px;
`

const KeyboardLeftWall = styled.div`
  background: rgba(250, 250, 250, 0.9);
  position: absolute;
  width: 40%;
  backface-visibility: hidden;
  height: 100%;
  transform: translate3d(-20px, 0, 20px) rotateY(-80deg);
  border-radius: 4px;
`

const KeyboardFrontWall = styled.div`
  background: rgba(250, 250, 250, 0.9);
  position: absolute;
  transition: 0.1s ease-in;
  width: 100%;
  height: 43%;
  transform: translate3d(-4px, 75px, 21px) rotateX(-80deg);
  border-radius: 4px;
`
const activeShadowStyles = `
  transform: translate3d(0, -10px, -20px);
  background: linear-gradient(#bbb, #bbb);
  box-shadow: -5px 5px 20px 10px #fff;
`
const activeCardStyles = `
background: #fff;
transform: translate3d(0, 0, 10px) rotateX(-15deg);
transform-origin: center bottom;
`
const activeKeyLeftWallStyles = `
transform: translate3d(-20px, 0, 15px) rotateY(-65deg);

`
const activeKeyFrontWallStyles = `
height: 23%;
width: 94%;
transform-origin: left top;
transform: translate3d(-4px, 98px, 16px) rotateX(-100deg);
`

const CardWrapper = styled.div`
  height: 100px;
  width: 100px;
  position: relative;
  transform-style: inherit;
  > * {
    transform-style: inherit;
    transition: 0.2s ease-in;
  }
  &:hover ${Shadow} {
    ${activeShadowStyles}
  }
  &:hover ${Card} {
    ${activeCardStyles}
  }
  &:hover ${KeyboardLeftWall} {
    ${activeKeyLeftWallStyles}
  }
  &:hover ${KeyboardFrontWall} {
    ${activeKeyFrontWallStyles}
  }
  ${({ active }) =>
    active &&
    `
    ${Shadow} {
      ${activeShadowStyles}
    }
    ${Card} {
      ${activeCardStyles}
    }
    ${KeyboardLeftWall} {
      ${activeKeyLeftWallStyles}
    }
    ${KeyboardFrontWall} {
      ${activeKeyFrontWallStyles}
    }
  `}
`

const KeyboardRow = styled.div`
  text-align: center;
  white-space: no-wrap;
  transform-style: inherit;
  ${CardWrapper} {
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
`
const Combo = ({ active, label }) => (
  <CardWrapper active={active}>
    <KeyboardFrontWall />
    <KeyboardLeftWall />
    <Card>{label}</Card>
    <Shadow />
  </CardWrapper>
)

const keyboard = [
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
  ["Z", "X", "C", "V", "B", "N", "M"],
]
const allKeys = keyboard.flat()

const content = {
  header: "Isometric Lab: Interactive QWERTY Keyboard",
  description:
    "Due to my recent obsession with isometric view (along with the forever obsession with keys as echoing with the Ctrl-N-Code Logo), I built a QWERTY Keyboard in isometric view with CSS. The holy grail CSS! The pressing effect is done with the mighty transform3d API.",
}
const checkInKeyboard = key => allKeys.includes(key.toUpperCase())

const TransformExp = ({ location }) => {
  const typeSoundRef = React.useRef()

  const [mute, setMute] = React.useState(false)

  const [press, setPress] = React.useState("")
  const [activeContent, setActiveContent] = React.useState()
  const [activeIndex, setActiveIndex] = React.useState()

  const playSound = React.useCallback(() => {
    if (typeSoundRef.current) {
      typeSoundRef.current.play()
    }
  }, [])

  const handleKeyUpdate = React.useCallback(
    newKey => {
      if (newKey) {
        playSound()
      }
      setPress(newKey.toUpperCase())
    },
    [playSound, setPress]
  )

  const handleContent = React.useCallback(
    paragraphKey => {
      setActiveContent(paragraphKey)
      setActiveIndex()
      setPress()
      let work = content[paragraphKey]
      const doWork = () => {
        if (work.length > 0) {
          const isKey = checkInKeyboard(work[0])
          handleKeyUpdate(isKey ? work[0] : "")
          setActiveIndex(content[paragraphKey].length - work.length)
          work = work.slice(1)
          setTimeout(() => {
            doWork()
          }, 300)
        } else {
          setActiveIndex()
          setPress("")
          setActiveContent()
        }
      }
      doWork()
    },
    [handleKeyUpdate]
  )

  const keydown = React.useCallback(
    e => {
      if (checkInKeyboard(e.key)) {
        handleKeyUpdate(e.key)
      } else {
        setPress("")
      }
    },
    [handleKeyUpdate, setPress]
  )

  React.useEffect(() => {
    window.addEventListener("keydown", keydown)
    if (typeSoundRef.current) {
      typeSoundRef.current.volume = 0.5
    }
    return () => {
      window.removeEventListener("keydown", keydown)
    }
  }, [keydown])

  React.useEffect(() => {
    if (typeSoundRef.current) {
      typeSoundRef.current.muted = mute
    }
  }, [mute])

  return (
    <Layout location={location}>
      <SEO title={content.header} />
      <Header>
        {content.header.split("").map((w, wIndex) => (
          <Word
            key={wIndex}
            active={wIndex === activeIndex && activeContent === "header"}
          >
            {w}
          </Word>
        ))}
      </Header>
      <audio ref={typeSoundRef}>
        <source
          src="https://freesound.org/data/previews/9/9744_28132-lq.mp3"
          type="audio/mpeg"
        />
      </audio>
      <BackToLab />

      <div style={{ position: "relative", marginTop: 8 }}>
        <Paragraph>
          <Wrap>
            {content.description.split("").map((w, wIndex) => (
              <Word
                key={wIndex}
                active={
                  wIndex === activeIndex && activeContent === "description"
                }
              >
                {w}
              </Word>
            ))}
            <div
              style={{
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <span>Try Me</span>
                <TypeButton
                  onClick={() => handleContent("header")}
                  disabled={!!activeContent}
                >
                  type lab title on Keyboard
                </TypeButton>
                <TypeButton
                  onClick={() => handleContent("description")}
                  disabled={!!activeContent}
                >
                  type description on Keyboard
                </TypeButton>
              </div>
              <div>
                or just <b>*type anything*</b> on your own keyboard
              </div>
              <div>
                or just <b>*hover around*</b> on the keyboard
              </div>
              <SoundButton onClick={() => setMute(prev => !prev)}>
                {mute ? "Set Sound On" : "Set Sound Off"}
              </SoundButton>
            </div>
          </Wrap>
          {press && <TypedKey>{press}</TypedKey>}
        </Paragraph>

        <div
          style={{
            backfaceVisibility: "hidden",
            transformStyle: "preserve-3d",
            width: 1300,
            height: "100vh",
            transform:
              "rotateX(60deg) rotateY(0) rotateZ(-45deg) translate3d(-200px, 80px ,0) scale(0.7)",
            transformOrigin: "center center",
          }}
        >
          {keyboard.map((row, rowIndex) => (
            <KeyboardRow keys={row.length} key={rowIndex}>
              {row.map(k => (
                <Combo key={k} label={k} active={k === press.toUpperCase()} />
              ))}
            </KeyboardRow>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default TransformExp
